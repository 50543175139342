<template>
  <div class="table-grid-item-col-1">
    <div class="custom-table-section">
      <div class="table-grid-item-col-2 order-list mt-5" >
        <div class="img-field">
          <img v-if="itemdata.Media && itemdata.Media[0] && itemdata.Media[0].Url" class="card-img-top" :src="itemdata.Media[0].Url" alt="Card image cap">             
        </div>
        <div class="table-grid-item-col-2-pd">
          <div class="label-field" v-html="itemdata.Name+', '+itemdata.Location.Country"></div>
          <div class="rating-star" v-if="itemdata.Rating =='0'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='0.5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polyline fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,96.488 23.984,115.423 30.862,75.319 1.726,46.917 41.993,41.065 60,4.577 "/>
            <path fill-rule="evenodd" d="M118.274,46.917"/>
            <polyline fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='1'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='1.5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polyline fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,96.488 23.984,115.423 30.862,75.319 1.726,46.917 41.993,41.065 60,4.577 "/>
            <path fill-rule="evenodd" d="M118.274,46.917"/>
            <polyline fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='2'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>       
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>           
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='2.5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polyline fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,96.488 23.984,115.423 30.862,75.319 1.726,46.917 41.993,41.065 60,4.577 "/>
            <path fill-rule="evenodd" d="M118.274,46.917"/>
            <polyline fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
          </div>
          <div class="rating-star" v-if="itemdata.Rating  =='3'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
          </div>
          <div class="rating-star" v-if="itemdata.Rating  =='3.5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polyline fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,96.488 23.984,115.423 30.862,75.319 1.726,46.917 41.993,41.065 60,4.577 "/>
            <path fill-rule="evenodd" d="M118.274,46.917"/>
            <polyline fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='4'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='4.5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polyline fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,96.488 23.984,115.423 30.862,75.319 1.726,46.917 41.993,41.065 60,4.577 "/>
            <path fill-rule="evenodd" d="M118.274,46.917"/>
            <polyline fill-rule="evenodd" fill="#FFFFFF" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 "/>
            </svg>
          </div>
          <div class="rating-star" v-if="itemdata.Rating =='5'" >
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg> 
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" baseProfile="tiny" id="Layer_1" x="0px" y="0px" width="120px" height="120px" viewBox="0 0 120 120" xml:space="preserve">
            <polygon fill-rule="evenodd" stroke="#000000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="  60,4.577 78.009,41.065 118.274,46.917 89.138,75.319 96.016,115.423 60,96.488 23.984,115.423 30.862,75.319 1.726,46.917   41.993,41.065 "/>
            </svg>
          </div>
          <div class="text-field" v-html="itemdata.Location.City"></div>
          <!-- <div class="text-field" v-html="'Room: '+itemdetail.Name+' ( '+ itemdetail.Rates[0].Board+', '+ (itemdetail.Rates[0].Pricing.IsRefundable==true?'Refundable':'Non-Refundable')+' )'"></div> -->
                          
        </div>
      </div>

      <div class="vx-col w-full mt-4" v-if="itemdataUnits">
        <span class="font-semibold">Rooms:</span>
      </div>
      <div class="vx-col w-full" v-if="itemdataUnits">
        <ag-grid-vue
          ref="agGridTable"
          class="ag-theme-material mb-4 w-100"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="itemdataUnits"
          :gridOptions="gridOptions"
          domLayout="autoHeight"
          rowSelection="single"
          :pagination="false"
          :suppressPaginationPanel="true"
          :style="{ width:'100%' }"
        ></ag-grid-vue>
      </div>

      <div class="guest-section" v-if="itemdata.Guests && itemdata.Guests.length > 0">
          <h4 class="guest-title">Guest List</h4>
          <div class="guests-table-layout ">
              <div class="guests-header-item">
                <div class="guest-details">Type</div>
                <div class="guest-details">Name</div>
                <div class="guest-details">Date of Birth</div>
              </div>
              <div v-for="(itemguestdetail, index) in itemdata.Guests" class="guests-body-item" :key="index">
                <div class="guest-body-detail-item" v-html="itemguestdetail.Type"></div>
                <div class="guest-body-detail-item" v-html="itemguestdetail.Title+' '+itemguestdetail.Firstname+' '+itemguestdetail.Surname"></div>
                <div class="guest-body-detail-item" v-html="new Date(itemguestdetail.DateOfBirth).toLocaleDateString('en-GB', {
day: 'numeric', month: 'short', year: 'numeric'
}).replace(/ /g, '-')"></div>
              </div>
            </div>
      </div>
    </div>
  </div>
</template>

<script>

import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  components: {
    TravelifyDisplayField,
    AgGridVue
  },
  props: {
    itemdata: { type: Object, required: true },
    isHotlist: { type: Boolean, default: false},
  },
  data () {
    return {
      itemdataUnits: []
    }
  },
  created () {
    this.itemdata && this.itemdata.Units && this.itemdata.Units.forEach(unitElem => {
      unitElem.Rates && unitElem.Rates.forEach(rateElem => {
        this.itemdataUnits.push({
          room: unitElem.Name,
          rate: rateElem.Name + ' - ' + rateElem.Board,
          netPrice: rateElem.Pricing.OriginalPrice +' ' + rateElem.Pricing.OriginalCurrency,
          sellingPrice: rateElem.Pricing.Price +' ' + rateElem.Pricing.Currency,
          selected: rateElem.Selected
        })
      })
    });
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Room', field: 'room', sortable: false, width: 200 },
      { headerName: 'Rate', field: 'rate', sortable: false,  width: 200 },
      { headerName: 'Net Price', field: 'netPrice', sortable: false,  width: 100 },
      { headerName: 'Selling Price', field: 'sellingPrice', sortable: false,  width: 100 },
    ]

    if( !this.isHotlist ) {
      this.columnDefs.push({ headerName: 'Selected', field: 'selected', sortable: false,  width: 100 })
    }

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
};
</script>

<style>
.table-grid-item {
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;
  }
  .table-grid-item .label-field h4,
  .table-grid-item-col-2 .label-field h4,
  .table-grid-item-col-5 .label-field h4,
  .table-grid-item-col-1 .label-field h4,
  .table-grid-item-col-1 .label-field,
  .table-grid-item-col-3 .label-field h4  {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .table-grid-item .text-field span,
  .table-grid-item-col-2 .text-field span,
  .table-grid-item-col-5 .text-field span{
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    word-break: break-word;
  }
  .table-grid-item-col-1{
    display: grid;
    grid-template-columns: auto;
    margin-bottom:10px;
  }
  .table-grid-item-col-1:last-child{
    margin:0;
  }
  .table-grid-item-col-1-pd{
    display: grid;
    grid-template-columns: auto;    
    padding-left: 25px;
  }
  .table-grid-item-col-2{
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .label-field .table-grid-item-col-2 {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .table-grid-item-col-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .table-grid-item-col-4.order-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .table-grid-item-col-2.order-list{
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .custom-table-section{
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .table-grid-item-col-5{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
  .text-field.multi-line {
    display: grid;
    grid-template-columns: auto;
  }
  .img-field img{
    width:100%;
    height:100%;
    border-radius: 5px;
  }  
  .order-details .ag-grid-table{
    height:320px;
  }
  .table-grid-item-col-2-pd {
    padding-left: 20px;
  }
  .img-field img {
    width: 180px;
    height: auto;
    margin: 0 auto;
}
</style>