<template>
  <div class="vx-col w-full" style="width:100%;">
    <div v-if="infoItems && infoItems.length > 0" class="vx-col w-full mt-4">
      <span class="font-semibold">Additional Information:</span>
    </div>
    
    <div v-if="infoItems && infoItems.length > 0" class="vx-col w-full">
      <vs-divider class="mb-0" />
    </div>

    <div class="info-items-container">
      <div v-for="(infoItem, index) in infoItems" :key="index">
        <div class="p-5">
          <h6>{{infoItem.Title}}</h6>
          <p class="text-sm">{{infoItem.Type}}</p>
          <p class="mt-2" v-html="infoItem.Text"></p>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  components: {
  },
  props: {
    infoItems: { type: Array, required: true }
  },
  data () {
    return {
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    
      
  },
  beforeMount () {
   
  },
  mounted () {
   
  },
  methods: {

  }
}
</script>

<style>
.info-items-container{
  height: 180px;
  overflow-y: scroll;
  width:100%;
}
</style>