<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <h4 class="mb-4">Tickets & Attractions</h4>
      </div>
      
      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Name" :value='itemdata && itemdata.Supplier.Name' />
      </div>

      <div class="vx-col w-1/3"></div>
      <div class="vx-col w-1/3">
        <div class="vx-row mb-2"  v-if="!isHotlist">
          <dl class="vx-col w-2/5 font-semibold">Booking Reference</dl>
          <dd class="vx-col w-3/5" :class="{ 'booking-ref-strike-through': orderItem.itemStatus==6}">{{ orderItem.itemBookingRef == null ? orderdata.orderStatus : orderItem.itemBookingRef }}</dd>
        </div>
      </div>

      <div class="vx-col w-1/3">
        <travelify-display-field label="Supplier Price" :value='supplierPrice' />
      </div>
      <div class="vx-col w-1/3">
        <travelify-display-field label="Rules Applied" :value='rulesApplied' />
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reference" :value='orderItem.itemCancelRef' />
      </div>

      <div class="vx-col w-1/3">
        <travelify-display-field label="Selling Price" :value='sellingPrice' />
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <div></div>
      </div>

      <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
        <travelify-display-field label="Cancel Reason" :value='orderItem.itemCancelReason' />
      </div>

      <div class="vx-col w-1/3">
        <div v-if="orderItem.itemDeadline" class="vx-col w-full">
          <travelify-display-field label="Payment/Ticketing Deadline" :value='orderItem.itemDeadline' />
        </div>
        <div v-if="orderItem.itemPayNowPrice" class="vx-col w-full">
          <travelify-display-field label="Pay Now Price" :value='orderItem.itemPayNowPrice' />
        </div>
      </div>
    </div>

    <div class="table-grid-item-col-1">
      <div class="table-grid-item-col-3 order-list mt-5">
        <div class="img-field">
          <img
            class="card-img-top"
            :src="itemdata.Media[0].Url"
            alt="Card image cap"
          />
        </div>
        <div class="table-grid-item-col-1-pd">
          <div class="label-field" v-html="itemdata.Name"></div>
          <div class="text-field" v-html="itemdata.Location.Name"></div>
          <div class="text-field" v-html="itemdata.Location.PostalCode"></div>
        </div>
        <div class="table-grid-item-col-1-pd">
          <div class="label-field" v-html="itemdata.Options[0].Name"></div>
          <div
            class="text-field"
            v-html="itemdata.Options[0].Description"
          ></div>
        </div>
      </div>
    </div>
    <info-items
      v-if="itemdata.Descriptions"
      class="mt-6"
      :infoItems="itemdata.Descriptions"
    />

    <div v-if="itemdata.Guests && itemdata.Guests.length > 0" class="vx-col w-full mt-6">
      <span class="font-semibold">Travellers:</span>
    </div>
    <div class="vx-col w-full">
      <passenger-list v-if="itemdata.Guests && itemdata.Guests.length > 0" :travellers="itemdata.Guests" />
    </div>
  </div>
</template>

<script>
import InfoItems from "./InfoItems.vue";
import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import PassengerList from './PassengerList.vue'

export default {
  components: {
    InfoItems,
    PassengerList,
    TravelifyDisplayField
  },
  props: {
    orderItem: { type: Object, required: true },
    itemdata: { type: Object, required: true },
    orderData: { type: Object, required: true }, // whole object from Order Details View
    isHotlist: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    sellingPrice() {
      if (!this.isHotlist) {
        return this.orderItem.itemSellingPrice;
      } else {
        return (
          this.itemdata.Pricing &&
          this.itemdata.Pricing.Price + " " + this.itemdata.Pricing.Currency
        );
      }
    },
    supplierPrice() {
      if (!this.isHotlist) {
        return this.orderItem.itemSupplierPrice;
      } else {
        return (
          this.itemdata.Pricing &&
          this.itemdata.Pricing.OriginalPrice +
            " " +
            this.itemdata.Pricing.OriginalCurrency
        );
      }
    },
    rulesApplied() {
      return (
        (this.itemData &&
          this.itemData.Pricing.RuleIds &&
          this.itemData.Pricing.RuleIds.join(",")) ||
        "No Rules"
      );
    },
  },
  methods: {},
};
</script>

<style>
.table-grid-item {
  display: grid;
  grid-template-columns: 20% 30% 20% 30%;
}
.table-grid-item .label-field h4,
.table-grid-item-col-2 .label-field h4,
.table-grid-item-col-5 .label-field h4,
.table-grid-item-col-1 .label-field h4,
.table-grid-item-col-1 .label-field,
.table-grid-item-col-3 .label-field h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
}
.table-grid-item .text-field span,
.table-grid-item-col-2 .text-field span,
.table-grid-item-col-5 .text-field span {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  word-break: break-word;
}
.table-grid-item-col-1 {
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 10px;
}
.table-grid-item-col-1:last-child {
  margin: 0;
}
.table-grid-item-col-1-pd {
  display: grid;
  grid-template-columns: auto;
  padding-left: 25px;
}
.table-grid-item-col-2 {
  display: grid;
  grid-template-columns: 20% 80%;
}
.label-field .table-grid-item-col-2 {
  display: grid;
  grid-template-columns: 40% 60%;
}
.table-grid-item-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.table-grid-item-col-4.order-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.table-grid-item-col-2.order-list {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border: 1px solid #d7d7dd;
  padding: 10px;
}
.custom-table-section {
  border: 1px solid #d7d7dd;
  padding: 10px;
}
.table-grid-item-col-5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
}
.text-field.multi-line {
  display: grid;
  grid-template-columns: auto;
}
.img-field img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.order-details .ag-grid-table {
  height: 320px;
}
.table-grid-item-col-2-pd {
  padding-left: 20px;
}
.img-field img {
  width: 180px;
  height: auto;
  margin: 0 auto;
}
</style>
