<template>
  <div class="carrier-logo-container">
     <img v-if="params.data.carrierLogo" :src="params.data.carrierLogo" 
      class="carrier-logo-img">
  </div>
</template>

<script>


export default {
  components: {
  },
  data () {
    return {
    }
  },
}
</script>

<style>
  .carrier-logo-container .carrier-logo-img {
    border-radius: 3px;
    height: 40px;
  }
</style>