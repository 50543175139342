<template>
  <div class="vx-col w-full mt-4">
    <div v-for="(extraGroup, index) in extraGroupsExtended" :key="index">
      <span class="">{{ extraGroup.type +' - '+ extraGroup.name }}</span>

      <ag-grid-vue
        ref="agGridTable"
        :components="grids[index].cellRendererComponents"
        class="ag-theme-material mb-10"
        :columnDefs="grids[index].columnDefs"
        :defaultColDef="grids[index].defaultColDef"
        :rowData="extraGroup.extras"
        :gridOptions="grids[index].gridOptions"
        domLayout="autoHeight"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
      ></ag-grid-vue>

    </div>
  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import { format } from 'date-fns'

export default {
  components: {
    AgGridVue,
  },
  props: {
    extraGroups: { type: Array, required: true }
  },
  data () {
    return {
      grids: [],

      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      cellRendererComponents: {
      },
      extraGroupsExtended: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    this.grids = this.extraGroups.map(e => ({
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      cellRendererComponents: {
      },
    }))

    this.extraGroupsExtended = this.extraGroups.map(eg => ({
      name: eg.Name,
      type: eg.Type,
      extras: eg.Extras.filter(x => x.QtySelected > 0).map(e => ({
        name: e.Name,
        qtySelected: e.QtySelected,
        price: e.Pricing.Price,
        currency: e.Pricing.Currency
      }))
    }))
      
  },
  beforeMount () {
    this.grids.forEach(x => {
      
      x.gridOptions = {};
      
      x.columnDefs =  [
        { headerName: 'Name', field: 'name', width: 250, suppressSizeToFit: true },
        { headerName: 'Qty Selected', field: 'qtySelected', width: 200, suppressSizeToFit: true },
        { headerName: 'Price', field: 'price', width: 200, suppressSizeToFit: true },
        { headerName: 'Currency', field: 'currency', width: 120, suppressSizeToFit: false },
      ]

      x.defaultColDef = {
        flex: 1,
        minWidth: 100,
        sortable: true,
        resizable: true,
        autoHeight: true,
        suppressMenu: true
      }

    })
    
  },
  mounted () {
    
    this.grids.forEach(x => {
      x.gridApi = x.gridOptions.api;
      x.gridColumnApi = x.gridOptions.columnApi;
      x.gridApi.sizeColumnsToFit();
    })
  },
  methods: {

  }
}
</script>

<style>

</style>