<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <h4 class="mb-4">Insurance</h4>
    </div>
    
    <div class="vx-col w-1/3">
      <travelify-display-field label="Supplier Name" :value='itemData && itemData.Supplier.Name' />
    </div>
    <div class="vx-col w-1/3"></div>
    <div class="vx-col w-1/3">
      <div class="vx-row mb-2"  v-if="!isHotlist">
        <dl class="vx-col w-2/5 font-semibold">Booking Reference</dl>
        <dd class="vx-col w-3/5" :class="{ 'booking-ref-strike-through': orderItem.itemStatus==6}">{{ orderItem.itemBookingRef == null ? orderdata.orderStatus : orderItem.itemBookingRef }}</dd>
      </div>
    </div>

    <div class="vx-col w-1/3">
      <travelify-display-field label="Supplier Price" :value='supplierPrice' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Rules Applied" :value='rulesApplied' />
    </div>
    <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
      <travelify-display-field label="Cancel Reference" :value='orderItem.itemCancelRef' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Selling Price" :value='sellingPrice' />
    </div>
    
    <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
      <div></div>
    </div>

    <div v-if="orderItem.itemStatus==6" class="vx-col w-1/3">
      <travelify-display-field label="Cancel Reason" :value='orderItem.itemCancelReason' />
    </div>

    <div class="vx-col w-1/3">
      <div v-if="orderItem.itemDeadline" class="vx-col w-full">
        <travelify-display-field label="Payment/Ticketing Deadline" :value='orderItem.itemDeadline' />
      </div>
      <div v-if="orderItem.itemPayNowPrice" class="vx-col w-full">
        <travelify-display-field label="Pay Now Price" :value='orderItem.itemPayNowPrice' />
      </div>
    </div>
    
    <vs-divider class="mx-4" />
    
    <div class="vx-col w-1/3">
      <travelify-display-field label="Policy Name" :value='itemData.PolicyName' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Quote" :value='itemData.QuoteType' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Cover" :value='itemData.CoverType' />
    </div>

    <div class="vx-col w-1/3">
      <travelify-display-field label="Region" :value='itemData.Region' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Start Date" :value='startDate' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="End Date" :value='endDate' />
    </div>

    <div class="vx-col w-1/3">
      <travelify-display-field label="Total" :value='(Math.round(total * 100) / 100).toFixed(2)' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Tax" :value='(Math.round(tax * 100) / 100).toFixed(2)' />
    </div>
    <div class="vx-col w-1/3">
      <travelify-display-field label="Currency" :value='itemData.Pricing.Currency' />
    </div>

    <div v-if="itemData && itemData.Travellers" class="vx-col w-full mt-4 mb-0">
      <span class="font-semibold">Insured Travellers:</span>
    </div>
    <vs-divider class="mx-4 my-1" />
    <div class="vx-col w-full">
      <passenger-list v-if="itemData && itemData.Travellers" :travellers="itemData.Travellers" />
    </div>
    
    <div class="vx-col w-full mt-4 mb-0">
      <span class="font-semibold">Policy Information:</span>
    </div>
    <vs-divider class="mx-4 my-1" />
    
    <div v-for="(policy, index) in policyList" :key="index"  class="vx-col w-full mt-2">
      <div class="text-sm">{{policy.Title}}</div>
      <div class="mt-1 mb-2">{{policy.Text}}</div>
    </div>

    <info-items v-if="itemData.Descriptions" class="mt-6" :infoItems="itemData.Descriptions" infoType="Descriptions" />
  </div>
</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import PassengerList from './PassengerList.vue'
import { format } from 'date-fns'
import InfoItems from './InfoItems.vue';

export default {
  components: {
    AgGridVue,
    TravelifyDisplayField,
    PassengerList,
    InfoItems
  },
  props: {
    orderItem: { type: Object, required: true },
    orderData: { type: Object, required: true }, // whole object from Order Details View
    isHotlist: { type: Boolean, default: false}
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
      },
      itemData: {},
      travellers: [],
      tax: null,
      total: null,
      policyList: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    startDate () {
      return this.itemData.StartDate && format(new Date(this.itemData.StartDate), 'dd MMM yyyy')
    },
    endDate () {
      return this.itemData.EndDate && format(new Date(this.itemData.EndDate), 'dd MMM yyyy')
    },
    rulesApplied () {
      return (this.itemData && this.itemData.Pricing.RuleIds && 
        this.itemData.Pricing.RuleIds.join(',') ) || 'No Rules'
    },
    sellingPrice () {
      if (!this.isHotlist) {
        return this.orderItem.itemSellingPrice
      } else {
        return this.itemData.Pricing && 
          this.itemData.Pricing.Price + ' ' + this.itemData.Pricing.Currency
      }
    },
    supplierPrice () {
      // itemData is different with itemdata
      if (!this.isHotlist) {
        return this.orderItem.itemSupplierPrice
      } else {
        return this.itemData.Pricing && 
          this.itemData.Pricing.OriginalPrice + ' ' + this.itemData.Pricing.OriginalCurrency
      }
    },
  },
  created () {
    this.itemData = JSON.parse(this.orderItem.itemData)[0]
    
    if(!this.itemData && this.orderItem.itemData){
      // Some this.orderItem.itemData are array while others are object
      this.itemData = JSON.parse(this.orderItem.itemData)
    }

    this.travellers = this.itemData.Travellers && this.itemData.Travellers.map(x => ({
      type: x.Type,
      fullName: (x.Title ? x.Title + ' ' : '') + x.Firstname + ' ' + x.Surname,
      dateOfBirth: x.DateOfBirth
    }))
    

    const pricing = this.itemData.Pricing
    if(pricing  && pricing.Breakdown) {

      this.tax = pricing.Breakdown
        .filter(x => x.Type === "Tax")
        .reduce((accumulator, x) => {
          return accumulator + (x.Qty * x.UnitPrice)
        }, 0);

      this.total = pricing.Breakdown
        .filter(x => x.Type !== "Tax")
        .reduce((accumulator, x) => {
          return accumulator + (x.Qty * x.UnitPrice)
        }, 0);
    }

    this.policyList = this.itemData.Descriptions || []

  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
    
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style>

</style>
